import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { faArrowUpFromBracket, faHouse, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ACL_VIEW_ROLES } from '@remento/types/acl';
import { AssetAlternativeMetadataType, RecordingAssetAlternativeType } from '@remento/types/alternative';
import { ReactionSentiment } from '@remento/types/reaction';
import { StoryShareLinkType } from '@remento/types/story';
import { getStoryDownloadName, getStoryTitle } from '@remento/utils/entity/story';
import { MediaPauseEvent, MediaPlayEvent, MediaTimeUpdateEventDetail, PlayerSrc } from '@vidstack/react';

import addEmojiIcon from '@/assets/icons/add-emoji-yellow.svg';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { removeToast, toast } from '@/components/RMToast/RMToast';
import { useElementSize } from '@/hooks/useElementSize';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useBooleanQueryParam } from '@/hooks/useQueryParam';
import { logger } from '@/logger';
import { PlayerContext } from '@/modules/media-player/PlayerContext';
import { getSigninPath, getStoriesPath, RementoPage } from '@/modules/routing';
import { SocialShareDialog } from '@/modules/stories/components/SocialShareDialog/SocialShareDialog';
import { StoryReactionButtons } from '@/modules/stories/components/StoryReactionButtons/StoryReactionButtons';
import { HighlightReelPlayerContainer } from '@/modules/stories/containers/HighlightReelPlayer.container';
import { StoryAnonymousReactionDialogContainer } from '@/modules/stories/containers/StoryAnonymousReactionDialog.container';
import { StoryDialogContainer } from '@/modules/stories/containers/StoryDialog.container';
import { StoryReactionDialogContainer } from '@/modules/stories/containers/StoryReactionDialog.container';
import { createStoriesManager, setActiveStoryId } from '@/modules/stories/states/stories.manager';
import { VideoManagerProvider } from '@/modules/stories/states/video.manager';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useAlternativeType, useAlternativeVideoUrl, useAssetAlternativesQuery } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { useRecordingQuery } from '@/services/api/recording';
import { useStoryQuery, useStoryShareLinkQuery } from '@/services/api/story';
import { Colors } from '@/styles/base/colors';
import { captureException } from '@/utils/captureException';
import { genitiveCase } from '@/utils/genitiveCase';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';

import {
  Body,
  ContentWrapper,
  Footer,
  Header,
  HeaderCloseButton,
  HeaderHomeButton,
  Page,
  PageWrapper,
  ReactionButton,
  SignInBanner,
  StoryTitle,
  VideoWrapper,
  Wordmark,
} from './StoryHighlightReelPage.styles';

export interface InternalStoryHighlightReelPage {
  storyId: string;
}

function InternalStoryHighlightReelPage({ storyId }: InternalStoryHighlightReelPage) {
  const { assetService, redirectService, storyViewerAnalyticsService } = useServices();
  const user = useUser();
  const navigate = useNavigate();

  // Queries
  const storyQuery = useStoryQuery(storyId);
  const projectQuery = useProjectQuery(user != null ? storyQuery.data?.projectId : null);
  const recordingId = storyQuery.data?.recordingsIds[0];
  const reelAlternativesQuery = useAssetAlternativesQuery(recordingId);
  const reelAlternative = useAlternativeType(
    reelAlternativesQuery.data,
    RecordingAssetAlternativeType.HIGHLIGHT_REEL,
    AssetAlternativeMetadataType.VIDEO,
  );

  // Check permission
  const userProjectRoles = useCurrentUserAclRoles(projectQuery.data?.acl ?? null);
  const canViewProject = hasRole(ACL_VIEW_ROLES, userProjectRoles ?? []);

  // Player Context
  const title = getStoryTitle(storyQuery.data) ?? storyQuery.data?.metadata.title ?? '';
  const reelUrl = useAlternativeVideoUrl(reelAlternative?.id) ?? '';
  const videoSrc = useMemo<PlayerSrc>(() => ({ src: reelUrl, type: 'application/x-mpegurl' }), [reelUrl]);

  // State
  const isMobile = useIsMobileViewport();
  // The projectId in the stories manager is not used for anything when accessing only one story (navigation is disabled)
  const storiesManager = useMemo(
    () => createStoriesManager([storyId], '', null, null, 'highlight-reel-standalone'),
    [storyId],
  );
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const storyShareLinkQuery = useStoryShareLinkQuery(storyId, StoryShareLinkType.HIGHLIGHT);
  const shareLink = storyShareLinkQuery.data ?? '';

  // Reaction state
  const [reactionDialogOpen, setReactionDialogOpen] = useBooleanQueryParam('reaction', false);
  const [currentReactionSentiment, setCurrentReactionSentiment] = useState<ReactionSentiment | null>(null);

  const recordingQuery = useRecordingQuery(storyQuery.data?.recordingsIds[0]);
  const personQuery = usePersonQuery(user?.personId);
  const userHasRecorded = personQuery.data?.refIds.includes(recordingQuery.data?.personId ?? '');

  // Can't react to your own recording
  const canReact = !user || !userHasRecorded;

  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first;

  // The highlight reel is always a video with 1:! aspect ratio.
  // To match the design, the buttons should have the same width as the video, and
  // we need to get the real element size to get it to work properly.
  const [videoWrapperRef, videoWrapperSize] = useElementSize();

  const handleViewFullStory = useCallback(() => {
    setActiveStoryId(storiesManager, storyId);
  }, [storiesManager, storyId]);

  const handleOpenReactionDialog = useCallback(() => {
    setReactionDialogOpen(true);
  }, [setReactionDialogOpen]);

  const handleSelectReaction = useCallback(
    (reactionSentiment: ReactionSentiment) => {
      setCurrentReactionSentiment(reactionSentiment);
      setReactionDialogOpen(true);
    },
    [setReactionDialogOpen],
  );

  const handleDownload = useCallback(async () => {
    if (reelAlternative == null) {
      logger.warn('VIDEO_NOT_LOADED');
      return;
    }

    const toastId = toast('Downloading', 'root-toast', 'default', {
      isLoading: true,
    });

    try {
      const filename = getStoryDownloadName(storyQuery.data, true) + '.mp4';
      const downloadUrl = await assetService.getAlternativeDownloadVideoUrl(reelAlternative.id, filename);
      window.location.assign(downloadUrl);
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    } finally {
      removeToast(toastId);
    }
  }, [assetService, reelAlternative, storyQuery.data]);

  const handleShare = useCallback(async () => {
    if (isMobile) {
      showShareSheetToast(await openShareSheet({ title, url: shareLink }));
    } else {
      setShareDialogOpen(true);
    }
  }, [isMobile, shareLink, title]);

  const handleCloseShareDialog = useCallback(() => {
    setShareDialogOpen(false);
  }, []);

  const handleShareAction = useCallback(
    async (source: 'email' | 'facebook' | 'twitter' | 'whatsapp' | 'download') => {
      if (source === 'download') {
        handleDownload();
        return;
      }

      switch (source) {
        case 'email': {
          location.href = `mailto:?subject=Watch ${genitiveCase(
            storytellerFirstName,
          )} story highlights: ${title}&body=Watch it on Remento - ${shareLink}`;
          break;
        }
        case 'facebook': {
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`, '_blank');
          break;
        }
        case 'twitter': {
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareLink)}&text=`, '_blank');
          break;
        }
        case 'whatsapp': {
          window.open(`https://api.whatsapp.com/send/?text=${encodeURIComponent(shareLink)}&type=custom_url`, '_blank');
          break;
        }
      }
    },
    [handleDownload, shareLink, title, storytellerFirstName],
  );

  const handleCopyShareLink = useCallback(async () => {
    showShareSheetToast(await openShareSheet({ url: shareLink }));
  }, [shareLink]);

  const handleClose = useCallback(() => {
    if (storyQuery.data == null) {
      return;
    }

    if (canViewProject) {
      navigate(getStoriesPath(storyQuery.data?.projectId));
    } else {
      navigate('/projects/stories');
    }
  }, [canViewProject, navigate, storyQuery.data]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  const handleLearnMore = useCallback(() => {
    window.open('https://remento.co', '_blank');
  }, []);

  // Analytics
  const videoStartTime = useRef<number | null>(null);
  const videoWatchedTotalDuration = useRef<number>(0);
  const videoCurrentTime = useRef<number>(0);

  const handlePlay = useCallback((event: MediaPlayEvent) => {
    videoStartTime.current = event.target.currentTime;
  }, []);

  const handleTimeUpdate = useCallback((event: MediaTimeUpdateEventDetail) => {
    videoCurrentTime.current = event.currentTime;
  }, []);

  const handlePause = useCallback(
    (event: MediaPauseEvent) => {
      if (videoStartTime.current === null) {
        logger.warn('VIDEO_PAUSE_NO_START_TIME');
        return;
      }

      videoWatchedTotalDuration.current += event.target.currentTime - videoStartTime.current;

      // Make sure the duration is rounded to the nearest 1/100
      const duration = Math.round(videoWatchedTotalDuration.current * 100) / 100;
      storyViewerAnalyticsService.onStoryWatched(duration, 'highlight');

      videoCurrentTime.current = 0;
      videoWatchedTotalDuration.current = 0;
      videoStartTime.current = null;
    },
    [storyViewerAnalyticsService],
  );

  // Trigger the analytics on unmount.
  // videoStartTime.current will be null if the analytics have already been fired.
  useEffect(() => {
    return () => {
      if (videoStartTime.current == null) {
        return;
      }

      videoWatchedTotalDuration.current += videoCurrentTime.current - videoStartTime.current;

      // Make sure the duration is rounded to the nearest 1/100
      const duration = Math.round(videoWatchedTotalDuration.current * 100) / 100;
      storyViewerAnalyticsService.onStoryWatched(duration, 'highlight');

      videoStartTime.current = null;
      videoWatchedTotalDuration.current = 0;
      videoCurrentTime.current = 0;
    };
  }, [storyViewerAnalyticsService]);

  if (reelUrl == null) {
    return <PageLoader />;
  }

  return (
    <Page>
      <Helmet>
        <meta name="theme-color" content={Colors.spruce[10]} />
      </Helmet>
      <VideoManagerProvider>
        <PageWrapper>
          <Header>
            <HeaderHomeButton>
              <RMIconButton
                icon={faHouse}
                tooltip={null}
                color="white"
                backgroundColor="transparent"
                stateColor="darken-neutral"
                size={isMobile ? 'lg' : 'xl'}
                onClick={() => {
                  window.location.assign('https://remento.co');
                }}
              />
            </HeaderHomeButton>

            {user ? (
              <RMText size="m" type="sans" bold color="inverse-on-surface-primary">
                Story highlights
              </RMText>
            ) : (
              <Wordmark color="neutral" destination="marketing" />
            )}

            <HeaderCloseButton>
              {user ? (
                <RMIconButton
                  icon={faXmark}
                  tooltip={null}
                  color="white"
                  backgroundColor="transparent"
                  stateColor="darken-neutral"
                  size={isMobile ? 'lg' : 'xl'}
                  onClick={() => {
                    handleClose();
                  }}
                />
              ) : (
                <RMButton background="none" color="secondary" onClick={handleSignIn}>
                  Login
                </RMButton>
              )}
            </HeaderCloseButton>
          </Header>

          <Body>
            <ContentWrapper>
              {!user && (
                <SignInBanner onClick={handleLearnMore}>
                  <RMText align="center" type="sans" size="s" color="on-surface-primary" bold>
                    Capture your own family’s stories
                  </RMText>
                </SignInBanner>
              )}
              <VideoWrapper ref={videoWrapperRef} style={{ width: videoWrapperSize.height + 'px' }}>
                <PlayerContext
                  title={title}
                  src={videoSrc}
                  crossOrigin
                  autoPlay={false}
                  playsInline
                  onPlay={handlePlay}
                  onPause={handlePause}
                  onTimeUpdate={handleTimeUpdate}
                  onError={(e) => {
                    logger.error('VIDEO_PLAYER.ERROR', e, e.error);
                  }}
                  onHlsError={(e: any) => {
                    logger.error('VIDEO_PLAYER.HLS.ERROR', e, e.error);
                  }}
                >
                  <HighlightReelPlayerContainer storyId={storyId} />
                </PlayerContext>
              </VideoWrapper>
              <StoryTitle size={isMobile ? 's' : 'm'} type="sans" bold color="inverse-on-surface-primary">
                {title}
              </StoryTitle>
              <Footer>
                <RMButton
                  background="gradient"
                  fullWidth={isMobile}
                  leftIcon={isMobile ? null : faArrowUpFromBracket}
                  onClick={handleShare}
                >
                  Share
                </RMButton>
                <RMButton background="neutral-on-inverse" fullWidth={isMobile} onClick={handleViewFullStory}>
                  View full story
                </RMButton>
                {canReact && (
                  <>
                    {isMobile ? (
                      <ReactionButton
                        background="neutral-on-inverse"
                        iconSize="extra-large"
                        onClick={handleOpenReactionDialog}
                      >
                        <img src={addEmojiIcon} />
                      </ReactionButton>
                    ) : (
                      <StoryReactionButtons
                        currentReaction={currentReactionSentiment}
                        onSelectReaction={handleSelectReaction}
                      />
                    )}
                  </>
                )}
              </Footer>
            </ContentWrapper>
          </Body>
        </PageWrapper>
        <SocialShareDialog
          open={shareDialogOpen}
          shareLink={shareLink}
          onShareAction={handleShareAction}
          onCopyShareLink={handleCopyShareLink}
          onClose={handleCloseShareDialog}
        />
        <StoryDialogContainer storiesManager={storiesManager} />

        {recordingId && (user == null || projectQuery.data != null) && canViewProject == true ? (
          <StoryReactionDialogContainer
            open={reactionDialogOpen}
            recordingId={recordingId}
            initialSentiment={currentReactionSentiment}
            page="highlight-reel-standalone"
            onClose={() => setReactionDialogOpen(null)}
          />
        ) : null}
        {recordingId && (user == null || projectQuery.data != null) && canViewProject == false ? (
          <StoryAnonymousReactionDialogContainer
            open={reactionDialogOpen}
            recordingId={recordingId}
            initialSentiment={currentReactionSentiment}
            page="highlight-reel-standalone"
            onClose={() => setReactionDialogOpen(null)}
          />
        ) : null}
      </VideoManagerProvider>
    </Page>
  );
}

export function StoryHighlightReelPage() {
  const params = useParams();

  return (
    <RementoPage type="default">
      <InternalStoryHighlightReelPage storyId={params.storyId ?? ''} />
    </RementoPage>
  );
}
